export enum VoucherClass {
  influencer = 'influencer',
  strategic_partner = 'strategic_partner',
  closed_user_group = 'closed_user_group',
}

export type ReferralVoucherData = {
  amount: number;
  type: string;
  code: string;
  is_valid: boolean;
  referrer: string;
  value_abs: number;
  value_rel: number;
  image: { url: string };
  class: VoucherClass;
};

export enum VoucherType {
  RELATIVE = 'relative-value',
  ABSOLUTE = 'absolute-value',
}
