/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorPageData } from './cosmicModules';

export { type OptionType } from '@finn/ui-components';

declare global {
  interface Window {
    Trustpilot?: {
      Modules: object;
      loadFromElement: (e: HTMLDivElement, t: boolean) => void;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google: any;
    ReactNativeWebView?: {
      // post message accepts JSON payload
      postMessage?: (payload: string) => void;
    };
    AF: (target, func, options?: object) => void;
    UC_UI?: {
      getServices: () => UsercentricsConsent[];
      showSecondLayer: () => void;
    };
    grecaptcha: unknown;
  }
}

export type PageHeadline = {
  title: string;
  description: string;
};

export interface IErrorPage {
  pageData: ErrorPageData;
  statusCode: 404 | 500;
  errorMessage: string;
  requestId?: string;
}

export enum PLPRoute {
  SUBSCRIPTION = '/subscribe',
  SALES = '/buy',
}

export enum InputColor {
  GREY = 'grey',
  WHITE = 'white',
}

export enum CollapseTypes {
  BORDERED = 'bordered',
}

export type UsercentricsConsent = {
  name: string;
  consent: {
    status: boolean;
  };
};
