import React from 'react';

import { MagicLinkButton } from './MagicLinkButton';
import { SocialLoginButton } from './SocialLoginButton';

export type SocialLoginMethod = 'Google' | 'Apple';
type Props = {
  socialButtonSeparator: string;
};

const loginMethods: SocialLoginMethod[] = ['Google', 'Apple'];

export const SocialLoginButtons: React.FC<Props> = ({
  socialButtonSeparator,
}) => {
  return (
    <div className="mt-2">
      <div className="finn-social-button-separator body-16-light mb-8 mt-2 text-center">
        {socialButtonSeparator}
      </div>
      {loginMethods.map((item) => (
        <div className="mb-4" key={item}>
          <SocialLoginButton method={item} />
        </div>
      ))}
      <MagicLinkButton />
    </div>
  );
};
