import { useHookFormTracking } from '@finn/ua-tracking';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import CheckoutContext from '~/contexts/Checkout';
import { LoginForm } from '~/modules/login/LoginForm';
import {
  FormFieldsEmail,
  FormFieldsLogin,
  FormFieldsRegistration,
  getValidationSchema,
} from '~/modules/login/schemas/validationSchema';
import { useLoginStore } from '~/modules/login/store/useLoginStore';

export const Login: React.FC<{
  isLoadingRestore?: boolean;
}> = ({ isLoadingRestore }) => {
  const loginScreen = useLoginStore((state) => state.loginScreen);
  const formValues = useLoginStore((state) => state.formValues);
  const setFormValues = useLoginStore((state) => state.setFormValues);
  const setServerErrorCode = useLoginStore((state) => state.setServerErrorCode);
  const { vehicle } = useContext(CheckoutContext);
  const vehiclePower = vehicle?.power || null;

  const form = useForm<
    FormFieldsEmail | FormFieldsLogin | FormFieldsRegistration
  >({
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema(loginScreen, vehiclePower)),
    defaultValues: formValues,
  });

  useHookFormTracking(form, loginScreen);

  useEffect(() => {
    // on unmount save the latest form values so user can continue from where they left after re-mounting
    return () => {
      setFormValues(form?.getValues() || {});
      setServerErrorCode(null);
    };
  }, [form, setFormValues, setServerErrorCode]);

  return (
    <FormProvider {...form}>
      <LoginForm isLoadingRestore={isLoadingRestore} />
    </FormProvider>
  );
};
