import axios from 'axios';

export const checkHasBusinessAccount = async (email: string) => {
  try {
    const response = await axios.post('/api/hasBusinessAccount', {
      email,
    });

    return response.data?.accountExists;
  } catch (e) {
    console.error(e);

    return false;
  }
};
