import { Modal, ModalContent } from '@finn/design-system/atoms/modal';

import { Login } from '~/modules/login/Login';

type LoginModalProps = {
  open: boolean;
  className?: string;
  onClose(): void;
};

export const LoginModal = ({ open, onClose, className }: LoginModalProps) => (
  <Modal
    variant="small"
    open={open}
    onOpenChange={(isOpen) => !isOpen && onClose()}
  >
    <ModalContent>
      <div className={className}>
        <Login />
      </div>
    </ModalContent>
  </Modal>
);
