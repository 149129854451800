export enum USState {
  PA = 'PA',
  NJ = 'NJ',
  DC = 'DC',
  CT = 'CT',
  MA = 'MA',
  VA = 'VA',
  MD = 'MD',
  NY = 'NY',
  NH = 'NH',
  RI = 'RI',
  VT = 'VT',
  ME = 'ME',
}
