import { LoginScreen, useLoginStore } from '../store/useLoginStore';
import { useInitialHandler } from './useInitialHandler';
import { useLoginHandler } from './useLoginHandler';
import { useMagicLinkHandler } from './useMagicLinkHandler';
import { useRegistrationHandler } from './useRegistrationHandler';

export const useSubmitHandler = () => {
  const loginScreen = useLoginStore((state) => state.loginScreen);
  const initialHandler = useInitialHandler();
  const loginHandler = useLoginHandler();
  const registrationHandler = useRegistrationHandler();
  const magicLinkHandler = useMagicLinkHandler();
  const forgotPasswordHandler = useMagicLinkHandler(true);

  switch (loginScreen) {
    case LoginScreen.registrationForm: {
      return registrationHandler;
    }
    case LoginScreen.loginForm: {
      return loginHandler;
    }
    case LoginScreen.magicLinkForm: {
      return magicLinkHandler;
    }
    case LoginScreen.magicLinkSuccess: {
      return magicLinkHandler;
    }
    case LoginScreen.forgotPasswordForm: {
      return forgotPasswordHandler;
    }
    case LoginScreen.forgotPasswordSuccess: {
      return forgotPasswordHandler;
    }
    default: {
      return initialHandler;
    }
  }
};
