import { isValidPhone, passwordRegex, validNameRegex } from '@finn/ua-auth';
import {
  ageBoundaries,
  LIMIT_VEHICLE_POWER_FOR_YOUNG_AGE,
} from '@finn/ua-constants';
import dayjs from 'dayjs';
import * as Yup from 'yup';

import { getAgeInYears } from '~/utils/time';

import { LoginScreen } from '../store/useLoginStore';

export type FormFieldsEmail = { email: string | null };
export type FormFieldsLogin = { email: string | null; password: string | null };
export type FormFieldsRegistration = {
  email: string | null;
  password: string | null;
  firstname: string | null;
  lastname: string | null;
  phone: string | null;
  birthday: Date | string | null;
};
export const validationSchemaEmail = Yup.object().shape<FormFieldsEmail>({
  email: Yup.string().email('yup.email').required('yup.required'),
});

export const validationSchemaLogin = Yup.object().shape<FormFieldsLogin>({
  email: Yup.string().email('yup.email').required('yup.required'),
  password: Yup.string().required('yup.required'),
});

export const getValidationSchemaRegistration = (excludeYoungDriver: boolean) =>
  Yup.object().shape<FormFieldsRegistration>({
    email: Yup.string().email('yup.email').required('yup.required'),
    password: Yup.string()
      .matches(passwordRegex, {
        message: 'yup.validPassword',
        excludeEmptyString: true,
      })
      .required('yup.required'),
    firstname: Yup.string()
      .matches(validNameRegex, {
        message: 'yup.string',
        excludeEmptyString: true,
      })
      .nullable()
      .required('yup.required'),
    lastname: Yup.string()
      .matches(validNameRegex, {
        message: 'yup.string',
        excludeEmptyString: true,
      })
      .nullable()
      .required('yup.required'),
    phone: isValidPhone('phoneNumber'),
    birthday: Yup.date()
      .test('Valid date', 'yup.validDate', (value) => dayjs(value).isValid())
      .test(
        'DOB',
        excludeYoungDriver ? 'yup.age>=23' : 'yup.age>=18',
        (value) => {
          const minAge = excludeYoungDriver
            ? ageBoundaries.MIN_NORMAL
            : ageBoundaries.MIN_EXCEPTIONAL;

          return getAgeInYears(value) >= minAge;
        }
      )
      .test('DOB', 'yup.age<=75', (value) => {
        const maxAge = ageBoundaries.MAX_NORMAL;

        return getAgeInYears(value) <= maxAge;
      })
      .nullable()
      .required('yup.required'),
  });

export const getValidationSchema = (
  loginScreen: LoginScreen,
  vehiclePower: number | null
) => {
  const excludeYoungDriver = vehiclePower
    ? vehiclePower > LIMIT_VEHICLE_POWER_FOR_YOUNG_AGE
    : false;
  switch (loginScreen) {
    case LoginScreen.registrationForm: {
      return getValidationSchemaRegistration(excludeYoungDriver);
    }
    case LoginScreen.loginForm: {
      return validationSchemaLogin;
    }
    default: {
      return validationSchemaEmail;
    }
  }
};
