import { DefaultLocale } from '@finn/ui-utils';
import { NextRouter } from 'next/router';

import { DealInfo, NextStage, Stage } from '~/types/checkout';

export const CheckoutSteps: { [key in Stage]: number } = {
  contact: 0,
  payment: 1,
  confirmation: 2,
};

const getNextCheckoutStepInfo = (
  nextStage: string,
  dealData: DealInfo
): string => {
  const nextStagePath = {
    'credit-score': 'credit-score',
    contact: 'contact',
    // TODO: Remove after 39,39 sprints deploy
    delivery: 'contact',
    payment: 'payment',
    confirmation: 'confirmation',
    financial: 'financial',
    thank_you: 'thank_you',
  };

  let nextStepAsUrl = `/checkout/${nextStagePath[nextStage as NextStage]}/${
    dealData.contactId
  }/${dealData.dealId}/${dealData.hash}`;

  if (dealData.isDirectCheckout) {
    nextStepAsUrl += '?is_direct_checkout=true';
  }

  return nextStepAsUrl;
};

export const goToNextCheckoutStep = async (
  router: NextRouter,
  nextStage: string,
  dealData: DealInfo,
  isCart?: boolean
) => {
  const url = getNextCheckoutStepInfo(nextStage, dealData);
  if (isCart) {
    await router.replace(url, undefined, { shallow: true });
  } else {
    await router.push(url, undefined, { shallow: true });
  }
};

export type HubspotDealInfo = {
  contactId: number | string;
  dealId: number | string;
  hash: string;
};

export const getStageLink = (
  stage: NextStage,
  dealData: DealInfo,
  locale = DefaultLocale
): string => {
  return `/${locale}/checkout/${stage}/${dealData.contactId}/${dealData.dealId}/${dealData.hash}`;
};
