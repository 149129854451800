import { Button } from '@finn/design-system/atoms/button';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

type Props = { email: string; open: boolean; onClose(): void };

export const BusinessDetectedModal: React.FC<Props> = ({
  open,
  onClose,
  email,
}) => {
  const i18n = useIntl();
  const track = useTrackingStore((state) => state.track);

  const b2bLoginClicked = useCallback(() => {
    track(TrackingEventName.CTA_CLICKED, {
      location: 'B2B Login',
    });
  }, [track]);

  const title = i18n.formatMessage({
    id: `userAccount.login.fleet_prompt.fleet.title`,
  });
  const body = i18n.formatMessage(
    {
      id: `userAccount.login.fleet_prompt.fleet.accountDetected`,
    },
    { email }
  );
  const linkHref = i18n.formatMessage({
    id: `userAccount.login.fleet_prompt.fleet.link_href`,
  });
  const linkText = i18n.formatMessage({
    id: `userAccount.login.fleet_prompt.fleet.link_text`,
  });
  const cancelText = i18n.formatMessage({
    id: `checkout.cancel`,
  });

  return (
    <Modal
      variant="small"
      open={open}
      onOpenChange={(isOpen) => !isOpen && onClose()}
    >
      <ModalContent>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
        <p className="body-14-regular">{body}</p>
        <ModalFooter>
          <Button href={linkHref} onClick={b2bLoginClicked}>
            {linkText}
          </Button>
          <Button variant="outline" onClick={onClose}>
            {cancelText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
