import { shouldShowFieldError } from '@finn/ui-utils';
import { FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePickerProps } from '@material-ui/pickers';
import { WrapperVariant } from '@material-ui/pickers/wrappers/Wrapper';
import { Dayjs } from 'dayjs';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import DatePicker from './';

// value and onChange are set manually
type Props = Omit<KeyboardDatePickerProps, 'value' | 'onChange'> & {
  disableInput?: boolean;
  showKeyboardIcon?: boolean;
  className?: string;
  variant?: WrapperVariant;
  onChange?: (date: Dayjs) => void;
  dateFormatVariant?: 'short' | 'long';
  // support external value and error for non-formik usage
  name: string;
  label: string;
  form: UseFormReturn;
};

const useStyles = makeStyles(() => ({
  inputAdornmentRoot: {
    display: 'none',
  },
}));

export const HookFormDatePicker: React.FC<Props> = ({
  shouldDisableDate,
  disableInput,
  InputAdornmentProps,
  showKeyboardIcon = true,
  name,
  form,
  ...rest
}) => {
  const classes = useStyles();
  const i18n = useIntl();
  const hidingInputAdornmentProps = {
    ...InputAdornmentProps,
    classes: { root: classes.inputAdornmentRoot },
  };

  //  hiding the date input icon
  const inputAdornmentProps =
    showKeyboardIcon === false
      ? hidingInputAdornmentProps
      : InputAdornmentProps;

  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field, fieldState }) => (
        <div style={{ position: 'relative' }}>
          <DatePicker
            disableInput={disableInput}
            error={shouldShowFieldError(fieldState, form.formState)}
            shouldDisableDate={shouldDisableDate}
            InputAdornmentProps={inputAdornmentProps}
            autoOk
            {...rest}
            {...field}
            value={field?.value || null}
          />
          {shouldShowFieldError(fieldState, form.formState) && (
            <FormHelperText error>
              {i18n.formatMessage({
                id: fieldState?.error?.message?.includes('Invalid Date')
                  ? 'yup.validDate'
                  : fieldState?.error?.message,
              })}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
};
