import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { checkEmailExistence } from '~/services/emailCheck';

import { LoginScreen, useLoginStore } from '../store/useLoginStore';

export const useInitialHandler = () => {
  const setLoginScreen = useLoginStore((state) => state.setLoginScreen);
  const setFormValues = useLoginStore((state) => state.setFormValues);
  const form = useFormContext();

  return useCallback(async () => {
    const emailValue = form?.getValues('email');
    setFormValues(form?.getValues() || {});
    const { success } = await checkEmailExistence(
      { email: emailValue.toLowerCase() },
      {},
      null
    );
    if (success) {
      setLoginScreen(LoginScreen.loginForm);
    } else {
      setLoginScreen(LoginScreen.registrationForm);
    }
  }, [form, setLoginScreen]);
};
