import { traceabilityHeadersBrowser } from '@finn/ui-utils';
import axios, { AxiosRequestConfig, Method } from 'axios';

import { EmailExistenceCheckBody } from '~/types/emailCheck';
import { isServer } from '~/utils/general';

type EmailExistenceCheckRequestOptions = {
  timeout?: number;
  isProxy?: boolean;
};

const callAuthEndpoint = async (
  endpoint: string,
  payload: {} | EmailExistenceCheckBody,
  { timeout, isProxy }: EmailExistenceCheckRequestOptions,
  method: Method = 'POST',
  token
) => {
  const BASE_URL = `${
    isProxy && !isServer() ? window.origin : process.env.NEXTAUTH_URL || ''
  }/api/auth`;

  const options: AxiosRequestConfig = {
    method,
    data: payload,
    timeout,
    url: `${BASE_URL}/custom/${endpoint}`,
    headers: {
      'X-Request-Source': 'web',
      ...traceabilityHeadersBrowser(),
    },
    cancelToken: token,
  };

  try {
    const { data, status } = await axios(options);

    return { ...data, status };
  } catch (error) {
    return error.response;
  }
};

export const CHECKOUT_TIMEOUT_THRESHOLD = 20000;

export const checkEmailExistence = async (
  emailInfo: EmailExistenceCheckBody,
  {
    timeout = CHECKOUT_TIMEOUT_THRESHOLD,
  }: {
    timeout?: number;
  },
  token
) =>
  await callAuthEndpoint(
    'checkIfEmailExists',
    emailInfo,
    {
      timeout,
      isProxy: true,
    },
    'POST',
    token
  );
