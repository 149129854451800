import { useRouter } from 'next/router';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { LoginScreen, useLoginStore } from '../store/useLoginStore';

export const SERVER_ERROR_CODES = new Set([
  'wrong_password',
  'user_does_not_exist',
  'user_already_exists',
  'invalid_domain',
]);

const getTitleId = (loginScreen: LoginScreen) => {
  switch (loginScreen) {
    case LoginScreen.loginForm:
      return 'checkout.loginTitle';
    case LoginScreen.registrationForm:
      return 'userAccount.loginScreen.registrationTitle';
    case LoginScreen.magicLinkForm:
      return 'userAccount.magicLinkModal.title';
    case LoginScreen.magicLinkSuccess:
      return 'userAccount.magicLinkModal.sent.title';
    case LoginScreen.forgotPasswordForm:
      return 'userAccount.resetPassword.title';
    case LoginScreen.forgotPasswordSuccess:
      return 'userAccount.resetPassword.successTitle';
    default:
      return 'userAccount.loginScreen.initialTitle';
  }
};

const getSubtitleId = (loginScreen: LoginScreen, isCart: boolean) => {
  switch (loginScreen) {
    case LoginScreen.loginForm:
      return 'userAccount.login.description';
    case LoginScreen.registrationForm:
      return `userAccount.loginScreen.registrationSubtitle${isCart ? 'Checkout' : ''}`;
    case LoginScreen.magicLinkForm:
      return 'userAccount.magicLinkModal.description';
    case LoginScreen.magicLinkSuccess:
      return 'userAccount.magicLinkModal.sent.description';
    case LoginScreen.forgotPasswordForm:
      return 'userAccount.resetPassword.description';
    case LoginScreen.forgotPasswordSuccess:
      return 'userAccount.resetPassword.successDescription';
    default:
      return `userAccount.loginScreen.initialSubtitle${isCart ? 'Checkout' : ''}`;
  }
};

const getCtaId = (loginScreen: LoginScreen) => {
  switch (loginScreen) {
    case LoginScreen.loginForm:
      return 'userAccount.button.loginNow';
    case LoginScreen.registrationForm:
      return 'userAccount.button.signUpNow';
    case LoginScreen.magicLinkForm:
      return 'userAccount.magicLinkModal.ctaLabel';
    case LoginScreen.magicLinkSuccess:
      return 'userAccount.magicLinkModal.sent.ctaLabel';
    case LoginScreen.forgotPasswordForm:
      return 'userAccount.resetPassword.ctaLabel';
    case LoginScreen.forgotPasswordSuccess:
      return 'userAccount.magicLinkModal.sent.ctaLabel';
    default:
      return 'userAccount.button.initialCta';
  }
};

export const useLoginText = () => {
  const i18n = useIntl();
  const router = useRouter();
  const serverErrorCode = useLoginStore((state) => state.serverErrorCode);
  const loginScreen = useLoginStore((state) => state.loginScreen);
  const form = useFormContext();

  const isCart = router.asPath.includes('cart');
  const title = i18n.formatMessage({
    id: getTitleId(loginScreen),
  });
  const subtitle = i18n.formatMessage(
    {
      id: getSubtitleId(loginScreen, isCart),
    },
    {
      email: form.getValues('email'),
    }
  );

  const buttonLabel = i18n.formatMessage({
    id: getCtaId(loginScreen),
  });

  let errorMessage = i18n.formatMessage({
    id: 'userAccount.serverErrors.generalError',
  });

  if (serverErrorCode && SERVER_ERROR_CODES.has(serverErrorCode)) {
    errorMessage = i18n.formatMessage({
      id: `userAccount.serverErrors.${serverErrorCode}`,
    });
  }

  const forgotPasswordLabel = i18n.formatMessage({
    id: 'userAccount.link.forgetPassword',
  });

  const emailLabel = i18n.formatMessage({ id: 'userAccount.field.email' });
  const socialButtonSeparator = i18n.formatMessage({
    id: 'userAccount.login.socialButtonSeparator',
  });
  const backButtonLabel = i18n.formatMessage({
    id: 'userAccount.magicLinkModal.backButtonLabel',
  });

  return {
    title,
    subtitle,
    buttonLabel,
    errorMessage,
    emailLabel,
    forgotPasswordLabel,
    socialButtonSeparator,
    backButtonLabel,
  };
};
