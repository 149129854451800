import { FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePickerProps } from '@material-ui/pickers';
import { WrapperVariant } from '@material-ui/pickers/wrappers/Wrapper';
import { Dayjs } from 'dayjs';
import { connect, FormikProps, FormikValues } from 'formik';
import get from 'lodash/get';
import React from 'react';
import { useIntl } from 'react-intl';

// TODO design-system: refactor !important styles after migration to new typography APP-1443
import DatePicker from './';

// value and onChange are set manually
type Props = Omit<KeyboardDatePickerProps, 'value' | 'onChange'> & {
  disableInput?: boolean;
  showKeyboardIcon?: boolean;
  className?: string;
  variant?: WrapperVariant;
  onChange?: (date: Dayjs) => void;
  dateFormatVariant?: 'short' | 'long';
  // support external value and error for non-formik usage
  externalError?: string;
  externalValue?: Date;
};

interface IOuterProps {
  formik: FormikProps<FormikValues>;
}

const useStyles = makeStyles(() => ({
  inputAdornmentRoot: {
    display: 'none',
  },
}));

const FormikDatePicker: React.FunctionComponent<Props & IOuterProps> = ({
  formik,
  shouldDisableDate,
  disableInput,
  InputAdornmentProps,
  variant,
  showKeyboardIcon = true,
  onChange,
  externalError,
  externalValue,
  ...rest
}) => {
  const classes = useStyles();
  const i18n = useIntl();
  const id = rest.name || '';
  const error = get(formik, `errors.${id}`, false) || externalError;
  const touched = get(formik, `touched.${id}`, false) || externalError;

  const hasError = !!(touched && error);
  const value = get(formik, `values.${id}`) || externalValue || null;

  // This in case if we want to hide the place of the icon (In case if we want hide the icon itself)
  const hidingInputAdornmentProps = {
    ...InputAdornmentProps,
    classes: { root: classes.inputAdornmentRoot },
  };

  //  hiding the date input icon
  const inputAdornmentProps =
    showKeyboardIcon === false
      ? hidingInputAdornmentProps
      : InputAdornmentProps;

  return (
    <>
      <DatePicker
        variant={variant}
        disableInput={disableInput}
        // Disable handleBlur in picker mode
        onBlur={disableInput ? () => {} : formik?.handleBlur}
        error={hasError}
        value={value}
        formik={formik}
        onChange={(date) => {
          formik?.setFieldValue?.(id, date?.toDate());
          if (onChange) onChange(date);
        }}
        shouldDisableDate={shouldDisableDate}
        InputAdornmentProps={inputAdornmentProps}
        autoOk
        {...rest}
      />
      {hasError && (
        <FormHelperText error className="body-12-light !text-red">
          {i18n.formatMessage({
            id: error?.includes('Invalid Date') ? 'yup.validDate' : error,
          })}
        </FormHelperText>
      )}
    </>
  );
};

export default connect<Props, FormikValues>(FormikDatePicker);
