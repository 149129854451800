import { validGermanZipCode } from '@finn/ua-auth';
import * as Yup from 'yup';

export const addressShape = {
  street: Yup.string().nullable().required('yup.required'),
  housenumber: Yup.string().nullable().required('yup.required'),
  zipcode: validGermanZipCode(), // US zipcode validation is overwritten after spreading this object
  city: Yup.string().nullable().required('yup.required'),
  state: Yup.string().notRequired().nullable(),
  extra: Yup.string().notRequired().nullable(),
};
