import { createFetcher, generateLocalizationHeaders } from '@finn/ui-utils';

import { stringifyParams } from '~/modules/location/google-maps/utils';

import type {
  AddressComponent,
  ClientPlaceAutocompleteParams,
  GeocodeResult,
  Place,
  PlaceAutocompleteResponse,
  PlaceAutocompleteResult,
  PlaceDetailsParams,
  PlaceDetailsResponse,
  PlaceNearby,
  PlacesNearbyParams,
  PlacesNearbyResponse,
  ReverseGeocodeParams,
  ReverseGeocodeResponse,
} from './types';
import { AddressType } from './types';

const fetcher = createFetcher({
  baseURL: '/',
  withCredentials: false,
});

/**
 * Get autocomplete predictions from AutocompleteService.
 * Throws an error if AutocompleteService is not available.
 * Returns null in case of network or API error.
 *
 * @param params - params required by Google Maps API
 * @param locale - a locale string (e.g. "en-US" or "de-DE")
 * @returns a list of PlaceAutocompleteResult or null in case of failure
 */
export async function getAutocompletePredictions(
  params: ClientPlaceAutocompleteParams,
  locale: string
): Promise<PlaceAutocompleteResult[] | null> {
  try {
    const response = await fetcher<PlaceAutocompleteResponse>({
      url: new URL('/api/maps/placeAutocomplete', window.location.origin),
      headers: generateLocalizationHeaders(locale),
      query: stringifyParams(params),
    });

    if (response === null) {
      return null;
    }

    if (response.status === 'ZERO_RESULTS') {
      return [];
    }

    if (response.status !== 'OK') {
      return null;
    }

    return response.predictions;
  } catch (err) {
    return null;
  }
}

/**
 * Reverse geocode location based on lat & lng via Google Geolocation API.
 *
 * @param params - params required by Google Maps API
 * @param locale - a locale string (e.g. "en-US" or "de-DE")
 * @returns a list of GeocodeResult or null in case of failure
 */
export async function reverseGeocode(
  params: ReverseGeocodeParams,
  locale: string
): Promise<GeocodeResult[] | null> {
  try {
    const response = await fetcher<ReverseGeocodeResponse>({
      url: new URL('/api/maps/reverseGeocode', window.location.origin),
      headers: generateLocalizationHeaders(locale),
      query: stringifyParams(params),
    });

    if (response === null || response.status !== 'OK') {
      return null;
    }

    return response.results;
  } catch (err) {
    return null;
  }
}

/**
 * Get place details by placeID from PlacesService.
 * Throws an error if PlacesService is not available.
 * Returns null in case of network or API error.
 *
 * @param params - params required by Google Maps API
 * @param locale - a locale string (e.g. "en-US" or "de-DE")
 * @returns an instance of Place or null in case of error
 */
export async function getPlaceDetails(
  params: PlaceDetailsParams,
  locale: string
): Promise<Place | null> {
  try {
    const response = await fetcher<PlaceDetailsResponse>({
      url: new URL('/api/maps/placeDetails', window.location.origin),
      headers: generateLocalizationHeaders(locale),
      query: stringifyParams(params),
    });

    if (response === null || response.status !== 'OK') {
      return null;
    }

    return response.result;
  } catch (err) {
    return null;
  }
}

/**
 * Get places nearby to a given place from PlacesService.
 * Throws an error if PlacesService is not available.
 * Returns null in case of network or API error.
 *
 * @param params - params required by Google Maps API
 * @param locale - a locale string (e.g. "en-US" or "de-DE")
 * @returns a list of PlaceNearby or null
 */
export async function getPlacesNearby(
  params: PlacesNearbyParams,
  locale: string
): Promise<PlaceNearby[] | null> {
  try {
    const response = await fetcher<PlacesNearbyResponse>({
      url: new URL('/api/maps/placesNearby', window.location.origin),
      headers: generateLocalizationHeaders(locale),
      query: stringifyParams(params),
    });

    if (response === null || response.status !== 'OK') {
      return null;
    }

    return response.results;
  } catch (err) {
    return null;
  }
}

/**
 * AddressComponentsContainer is used to abstract getter utilities from a concrete API response type,
 * since both Geocoder and PlacesService return objects with `address_components`,
 * and it's possible to use both with these utilities.
 *
 * `address_components` is kept optional since it's optional in PlacesResult.
 */
type AddressComponentsContainer = {
  address_components?: AddressComponent[];
};

/** Get a long component value from gMaps place result */
export function getLongName(
  place: AddressComponentsContainer | null,
  type: AddressType
): string | null {
  return (
    place?.address_components?.find((c) => c.types.includes(type))?.long_name ??
    null
  );
}

/** Get a short component value from gMaps place result */
export function getShortName(
  place: AddressComponentsContainer | null,
  type: AddressType
): string | null {
  return (
    place?.address_components?.find((c) => c.types.includes(type))
      ?.short_name ?? null
  );
}

/** Get a zip code from a gMaps place result */
export const getZipCode = (place: AddressComponentsContainer | null) =>
  getShortName(place, AddressType.postal_code);

/** Get a street name from a gMaps place result */
export const getStreetName = (place: AddressComponentsContainer | null) =>
  getShortName(place, AddressType.route);

/** Get a street number from a gMaps place result */
export const getStreetNumber = (place: AddressComponentsContainer | null) =>
  getLongName(place, AddressType.street_number);

/** Get a US state name from a gMaps place result */
export const getStateLong = (place: AddressComponentsContainer | null) =>
  getLongName(place, AddressType.administrative_area_level_1);

/** Get a US state two-letter code from a gMaps place result */
export const getStateShort = (place: AddressComponentsContainer | null) =>
  getShortName(place, AddressType.administrative_area_level_1);

export const getCountryShort = (place: AddressComponentsContainer | null) =>
  getShortName(place, AddressType.country);

/** Get a US city name from gMaps place result */
export const getLocationName = (place: AddressComponentsContainer | null) => {
  const postalTown = getLongName(place, AddressType.postal_town);
  const locality = getLongName(place, AddressType.locality);
  const subLocalityLevel1 = getLongName(place, AddressType.sublocality_level_1);
  const areaLevel2 = getLongName(
    place,
    AddressType.administrative_area_level_2
  );
  const areaLevel1 = getLongName(
    place,
    AddressType.administrative_area_level_1
  );

  return (
    postalTown || locality || subLocalityLevel1 || areaLevel2 || areaLevel1
  );
};

/** Get a formatted location label for a location in the US from gMaps place result */
export const getLocationLabel = (place: AddressComponentsContainer | null) => {
  if (!place) {
    return null;
  }

  const zipCode = getZipCode(place);
  const stateCode = getStateShort(place);
  const countryCode = getCountryShort(place);

  const locationNameParts = [zipCode];

  if (countryCode === 'US') {
    locationNameParts.push(stateCode);
  } else {
    locationNameParts.push(countryCode);
  }

  return locationNameParts.filter(Boolean).join(', ');
};

const RADIUS_INITIAL_METERS = 1000;
const RADIUS_MAXIMUM_METERS = 50000;

/**
 * `getNearbyPlaceWithZip` looks up for a place with a zip code nearby to a given place,
 * doubling the search radius until it reaches the limit of 50000 meters imposed by Maps API.
 *
 * As of now, it modifies the given place object to keep the name of the place consistent.
 * Some places actually have zip codes, but the place suggested by Autocomplete may not have one,
 * and the place closest to it may have a different name in `address_components`,
 * which will affect the location label in returned from `getLocationLabel`.
 *
 * This will likely to be removed in future when we migrate to accepting and showing zip codes only.
 *
 * @param place - a response from `getPlaceDetails`
 * @returns a PlaceResult if the zip code is found, or null
 */
async function getNearbyPlaceWithZip(place: Place): Promise<Place | null> {
  let radius = RADIUS_INITIAL_METERS;

  if (
    typeof place.geometry?.location.lat !== 'number' ||
    typeof place.geometry?.location.lng !== 'number'
  ) {
    console.error('Cannot find lat or lng in place.geometry');

    return null;
  }

  try {
    while (radius < RADIUS_MAXIMUM_METERS) {
      const nearbyPlaces = await getPlacesNearby(
        {
          location: {
            lat: place.geometry.location.lat,
            lng: place.geometry.location.lng,
          },
          radius,
          type: AddressType.postal_code,
        },
        'en-US'
      );

      // A small workaround to keep existing behavior, but provide the zip from the object.
      // Before it was possible that getZipCode could return `null`.
      // Most likely, this hack will not be needed after we migrate to accepting zipcodes only.
      if (nearbyPlaces !== null) {
        const zipcode = nearbyPlaces[0].name;
        if (!zipcode) {
          return null;
        }

        place.address_components.push({
          long_name: zipcode,
          short_name: zipcode,
          types: [AddressType.postal_code],
        });

        return place;
      }

      radius *= 2;
    }
  } catch (error) {
    console.error(error);
  }

  return null;
}

type GetPlaceWithZipParams = {
  place_id: string;
};

/**
 * `getPlaceWithZip` fetches place details by a given `placeId`,
 * and returns the place data object if it contains a zip code in `address_components`,
 * or looks up the zip code in nearby places.
 *
 * @param params - params required by Google Maps API
 * @param locale - a locale string (e.g. "en-US" or "de-DE")
 * @returns a Place if a place with the zip code is found, or null
 */
export async function getPlaceWithZip(
  { place_id }: GetPlaceWithZipParams,
  locale: string
): Promise<Place | null> {
  try {
    const placeDetails = await getPlaceDetails({ place_id }, locale);

    if (placeDetails && !getZipCode(placeDetails)) {
      return await getNearbyPlaceWithZip(placeDetails);
    }

    return placeDetails;
  } catch (error) {
    console.error(error);
  }

  return null;
}
