import { validateEmail } from '@finn/ui-utils';
import dayjs from 'dayjs';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { checkHasBusinessAccount } from '~/modules/login/api';

export enum LoginScreen {
  emailCheckForm = 'emailCheckForm',
  loginForm = 'loginForm',
  registrationForm = 'registrationForm',
  magicLinkForm = 'magicLinkForm',
  magicLinkSuccess = 'magicLinkSuccess',
  forgotPasswordForm = 'forgotPasswordForm',
  forgotPasswordSuccess = 'forgotPasswordSuccess',
}

type LoginStoreState = {
  setHasBusinessAccount: (email?: string) => void;
  loginScreen: LoginScreen;
  magicLinkSentCount: number;
  setMagicLinkSentCount: (value: number) => void;
  setLoginScreen: (screen: LoginScreen) => void;
  isLoadingRestore?: boolean;
  setIsLoadingRestore: (value: boolean) => void;
  submittedLogin?: boolean;
  setSubmittedLogin: (value: boolean) => void;
  isRegistration?: boolean;
  setIsRegistration: (value: boolean) => void;
  hasBusinessAccount?: boolean;
  serverErrorCode?: string | null;
  setServerErrorCode: (value: string | null) => void;
  formValues: Record<string, any>;
  setFormValues: (values: Record<string, any>) => void;
};

export const useLoginStore = create<LoginStoreState>()(
  devtools(
    immer((set) => ({
      setHasBusinessAccount: async (email) => {
        const isValidEmail = validateEmail(email || '');
        if (isValidEmail) {
          const hasBusinessAccount = await checkHasBusinessAccount(email);
          set((state) => {
            state.hasBusinessAccount = hasBusinessAccount;
          });
        }
      },
      magicLinkSentCount: 0,
      setMagicLinkSentCount: (value) => {
        set((state) => {
          state.magicLinkSentCount = value;
        });
      },
      formValues: {},
      setFormValues: (values: Record<string, any>) => {
        set((state) => {
          const { birthday, ...rest } = values;
          if (dayjs(birthday).isValid()) {
            state.formValues = values;
          } else {
            state.formValues = rest;
          }
        });
      },
      loginScreen: LoginScreen.emailCheckForm,
      setLoginScreen: (screen: LoginScreen) => {
        set((state) => {
          state.loginScreen = screen;
          state.serverErrorCode = null;
        });
      },
      isLoadingRestore: false,
      setIsLoadingRestore: (value) => {
        set((state) => {
          state.isLoadingRestore = value;
        });
      },
      submittedLogin: false,
      setSubmittedLogin: (value) => {
        set((state) => {
          state.submittedLogin = value;
        });
      },
      isRegistration: false,
      setIsRegistration: (value) => {
        set((state) => {
          state.isRegistration = value;
        });
      },
      hasBusinessAccount: false,
      setServerErrorCode: (value) => {
        set((state) => {
          state.serverErrorCode = value;
        });
      },
    })),
    { name: 'LoginStore' }
  )
);
