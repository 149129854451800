import {
  Input,
  InputProps,
  TelInputChangeEvent,
} from '@finn/design-system/atoms/input';
import { shouldShowFieldError } from '@finn/ui-utils';
import React, { useCallback } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

// interface can not extend a union-type
type Props = Omit<InputProps, 'form'> & {
  form: UseFormReturn;
};

export const HookFormPhoneInput: React.FunctionComponent<Props> = ({
  placeholder,
  label,
  form,
  name,
}) => {
  const i18n = useIntl();

  // phone input library automatically adds and removes space parentheses etc.
  // So we need a custom handler to update the form value, cant use the input field directly
  const handleChange = useCallback(
    (res: TelInputChangeEvent) => {
      const newValue = res?.phone;
      const hasUserInput = newValue?.trim() !== `+${res?.country?.dialCode}`;
      form?.setValue?.(name, newValue, {
        shouldValidate: hasUserInput,
        shouldDirty: hasUserInput,
        shouldTouch: hasUserInput,
      });
    },
    [form, name]
  );

  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <Input
            type="tel"
            autoComplete="tel"
            value={field.value}
            name={field.name}
            placeholder={placeholder}
            label={label}
            error={
              shouldShowFieldError(fieldState, form.formState) &&
              i18n.formatMessage({
                id: fieldState?.error?.message?.includes('Invalid Date')
                  ? 'yup.validDate'
                  : fieldState?.error?.message,
              })
            }
            onChange={handleChange}
          />
        );
      }}
    />
  );
};
