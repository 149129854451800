import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { useEffect } from 'react';

import { LoginScreen, useLoginStore } from '../store/useLoginStore';

export const useTrackScreenSwitching = () => {
  const loginScreen = useLoginStore((state) => state.loginScreen);
  const track = useTrackingStore((state) => state.track);

  useEffect(() => {
    switch (loginScreen) {
      case LoginScreen.emailCheckForm:
        track(TrackingEventName.LOGIN_OPTION_FORM_OPENED, {});
        break;
      case LoginScreen.loginForm:
        track(TrackingEventName.LOGIN_FORM_OPENED, {});
        break;
      case LoginScreen.magicLinkForm:
        track(TrackingEventName.MAGIC_LINK_FORM_OPENED, {});
        break;
      case LoginScreen.magicLinkSuccess:
        track(TrackingEventName.MAGIC_LINK_SUCCESS_FORM_OPENED, {});
        break;
      case LoginScreen.registrationForm:
        track(TrackingEventName.REGISTER_FORM_OPENED, {});
        break;
      case LoginScreen.forgotPasswordForm:
        track(TrackingEventName.FORGOT_PASSWORD_FORM_OPENED, {});
        break;
      case LoginScreen.forgotPasswordSuccess:
        track(TrackingEventName.FORGOT_PASSWORD_SUCCESS_FORM_OPENED, {});
        break;
    }
  }, [loginScreen, track]);
};
