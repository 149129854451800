import { accountCreated, DeprecatedOrigin } from '@finn/cp-utils';
import { checkAccountSwitch, register } from '@finn/ua-auth';
import { ModalKey, useCloseModal } from '@finn/ua-modals';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { recaptchaCall } from '@finn/ui-components';
import {
  config,
  CookieKeys,
  getClientCookie,
  getSession,
  Locale,
  removeLocalTimezoneOffset,
  useCurrentLocale,
} from '@finn/ui-utils';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useCallback, useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import ExtendedAccountContext from '~/contexts/ExtendedAccount';

import { useLoginStore } from '../store/useLoginStore';

export const useRegistrationHandler = () => {
  const { isUSA, locale } = useCurrentLocale();
  const form = useFormContext();
  const setServerErrorCode = useLoginStore((state) => state.setServerErrorCode);
  const setFormValues = useLoginStore((state) => state.setFormValues);
  const trackSubmitClick = useCallback((data) => {
    interactionTrackingEvent(TrackingEventName.REGISTER_CTA_CLICKED, {
      authenticationMethod: 'email',
      ...(data || {}),
    });
  }, []);
  const trackFailure = useCallback((error = '', data) => {
    interactionTrackingEvent(TrackingEventName.ACCOUNT_CREATION_FAILED, {
      authenticationMethod: 'email',
      error,
      ...(data || {}),
    });
  }, []);
  const router = useRouter();
  const isCart = router.asPath.includes('cart');
  const setSubmittedLogin = useLoginStore((state) => state.setSubmittedLogin);
  const closeModal = useCloseModal();

  const { setExtendedAccountInProgress, createLead } = useContext(
    ExtendedAccountContext
  );

  return useCallback(async () => {
    setFormValues(form?.getValues() || {});
    const email = form?.getValues('email') || '';
    const password = form?.getValues('password') || '';
    const firstname = form?.getValues('firstname') || '';
    const lastname = form?.getValues('lastname') || '';
    const phone = form?.getValues('phone') || '';
    const birthday = form?.getValues('birthday') || '';
    const shouldCreateLead = !!(
      firstname &&
      lastname &&
      phone &&
      birthday &&
      isCart
    );

    setServerErrorCode(null);

    trackSubmitClick({ source: 'embedded-login' });
    if (shouldCreateLead) {
      setExtendedAccountInProgress(true);
    }
    setSubmittedLogin(true);
    // wait for hubspot form to be submitted as we want this to be the first contact with hubspot
    const token = await recaptchaCall();
    const result = await register(
      email,
      password,

      locale as Locale,
      token,
      getClientCookie(CookieKeys.E2E_SECRET) ||
        config.RECAPTCHA_E2E_SECRET ||
        '',
      {
        firstname,
        lastname,
        phone,
        birthday: removeLocalTimezoneOffset(dayjs(birthday)?.toDate())
          ?.getTime()
          ?.toString(),
      }
    );
    if (result.ok) {
      if (shouldCreateLead) {
        try {
          await getSession();
          await createLead(form?.getValues());
        } catch {
          setExtendedAccountInProgress(false);
        }
      } else {
        await getSession();
      }
      closeModal(ModalKey.LOGIN);
      accountCreated({
        contact_id: '',
        origin: DeprecatedOrigin.CHECKOUT as any,
      });
      await checkAccountSwitch();
    } else {
      trackFailure(result.error, { source: 'embedded-login' });
      setServerErrorCode(result.error);
    }
    setSubmittedLogin(false);
  }, [
    setFormValues,
    form,
    isCart,
    setServerErrorCode,
    trackSubmitClick,
    setSubmittedLogin,
    isUSA,
    locale,
    setExtendedAccountInProgress,
    createLead,
    trackFailure,
  ]);
};
