import { getLocaleFromContext } from '@finn/ui-utils';

import { ErrorPageData } from '~/types/cosmicModules/errorPage';
import { IErrorPage } from '~/types/general';
import { NextServerSideContext } from '~/types/nextjs';
import { getCachedCosmicObject } from '~/utils/cosmic';

export const getServerSidePropsWithError = async (
  ctx: NextServerSideContext,
  error: Error,
  optionalRequestId?: string,
  errorPage404Message?: string
) => {
  const pageData: ErrorPageData = await getCachedCosmicObject({
    slug: 'static-error-page',
    locale: getLocaleFromContext(ctx),
  });
  let message = error.message;
  let statusCode = 500;

  if (error.message === '404') {
    statusCode = 404;
    message = errorPage404Message || 'Not Found';
  }

  if (ctx?.res) {
    ctx.res.statusCode = statusCode;
  }

  const requestId = optionalRequestId || null;

  return {
    props: {
      statusCode,
      pageData,
      requestId,
      errorMessage: message,
    } as IErrorPage,
  };
};
