import { SocialApple } from '@finn/design-system/icons/social-apple';
import { SocialGoogle } from '@finn/design-system/icons/social-google';
import React from 'react';

import { SocialLoginMethod } from './SocialLoginButtons';

export const MatchingIcon: React.FC<{ method: SocialLoginMethod }> = ({
  method,
}) => {
  switch (method) {
    case 'Google':
      return <SocialGoogle width={24} height={24} />;
    case 'Apple':
      return <SocialApple width={24} height={24} />;
    default:
      return null;
  }
};
